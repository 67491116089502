import { React, useState, useRef } from 'react';
import validate from './validate';
import useForm from './useForm';
import {
    HomeBackButton,
    SignUpContainer,
    TOS,
    SignUpLogo,
    InputContainer,
    InputSection,
    InputWrapper,
    Label,
    Input,
    InputCheckbox,
    LabelCheckbox,
    SubmitSection,
    TOSCheckbox,
    TOSlink,
    LegalPopup,
    LinkReadMore,
    ConfirmCenter
} from './SignUpFormElements';
import { BiHome } from "react-icons/bi";
import { motion } from "framer-motion";
import "../animationStyle.css";
// import './formstyle.css';
import Popup from 'reactjs-popup';
import Logo from '../../images/logov2.png';

const SignUp = () => {

    const [isChecked, setIsChecked] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate
    );

    const checkHandler = () => {
        setIsChecked(!isChecked);
    }

    if (isChecked === false) {
        values.tos = false;
    } else if (isChecked === true) {
        values.tos = true;
    }

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const handleSubmitSignup = (e) => {
        handleSubmit(e);
        closeTooltip(e);
    }

    return (
        <SignUpContainer>
            <HomeBackButton to="/">
                <motion.div className="BackHomeButton" whileTap={{ scale: 0.9 }}>
                    <BiHome size={30} color="#5b7329" />
                </motion.div>
            </HomeBackButton>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                className="MainInputWrapper"
            >
                <SignUpLogo src={Logo} alt="Tuinure" />
                <InputContainer noValidate>
                    <InputSection>
                        <InputWrapper>
                            <Label>First name*</Label>
                            <Input name="firstName" placeholder="Enter your first name" autoComplete="new-password" value={values.firstName} onChange={handleChange} type="text" required="required" />
                            {errors.firstName && <p className="Errorstyle">{errors.firstName}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Last name*</Label>
                            <Input name="lastName" placeholder="Enter your last name" autoComplete="new-password" value={values.lastName} onChange={handleChange} type="text" required="required" />
                            {errors.lastName && <p className="Errorstyle">{errors.lastName}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Business e-mail*</Label>
                            <Input name="emailAddress" placeholder="Enter your business e-mail" autoComplete="new-password" onChange={handleChange} type="email" required="required" />
                            {errors.emailAddress && <p className="Errorstyle">{errors.emailAddress}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Business name*</Label>
                            <Input name="businessName" placeholder="Enter your business name" autoComplete="new-password" value={values.businessName} onChange={handleChange} type="text" required="required" />
                            {errors.businessName && <p className="Errorstyle">{errors.businessName}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Business phone number*</Label>
                            <Input name="phoneNumber" placeholder="Enter your business phone number" autoComplete="new-password" value={values.phoneNumber} onChange={handleChange} type="tel" required="required" />
                            {errors.phoneNumber && <p className="Errorstyle">{errors.phoneNumber}</p>}
                        </InputWrapper>
                    </InputSection>
                    <InputSection>
                        <InputWrapper>
                            <Label>Business address*</Label>
                            <Input name="address" placeholder="Enter your business address" autoComplete="new-password" value={values.address} onChange={handleChange} type="text" required="required" />
                            {errors.address && <p className="Errorstyle">{errors.address}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Short description of your shop*</Label>
                            <Input name="description" placeholder="Enter a short shop description" autoComplete="new-password" value={values.description} onChange={handleChange} required="required" />
                            {errors.description && <p className="Errorstyle">{errors.description}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Instagram URL*</Label>
                            <Input name="socialOne" placeholder="Link to your instagram account" autoComplete="new-password" value={values.socialOne} onChange={handleChange} type="url" />
                            {errors.socialOne && <p className="Errorstyle">{errors.socialOne}</p>}
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Website URL</Label>
                            <Input name="website" placeholder="Link to your website" autoComplete="new-password" value={values.website} onChange={handleChange} type="url" />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Facebook URL</Label>
                            <Input name="socialTwo" placeholder="Link to your facebook account" autoComplete="new-password" value={values.socialTwo} onChange={handleChange} type="url" />
                        </InputWrapper>
                        <TOSCheckbox><InputCheckbox name="tos" type="checkbox" value={values.tos} checked={isChecked} onChange={checkHandler} required="required" /> <LabelCheckbox>Accept the <TOSlink to="/termsofservice">terms of service</TOSlink>.*</LabelCheckbox></TOSCheckbox>
                        {errors.tos && <p className="ErrorstyleCheckbox">{errors.tos}</p>}
                        <SubmitSection>
                            <Popup className="my-popup" ref={ref} trigger={<motion.button type="button" className="SignUpButton" whileTap={{ scale: 0.9 }}>Confirm!</motion.button>}>
                                <LegalPopup>I hereby confirm that I comply with applicable national food and beverages making and selling law and safety requirements and accept the <LinkReadMore to="/termsofservice">terms of service</LinkReadMore>.</LegalPopup>
                                <ConfirmCenter>
                                    <motion.button type="submit" className="ConfirmButton" onClick={handleSubmitSignup} whileTap={{ scale: 0.9 }}>Confirm</motion.button>
                                </ConfirmCenter>
                            </Popup>
                        </SubmitSection>
                    </InputSection>
                </InputContainer>
            </motion.div>
            <motion.div className="TOS" whileTap={{ scale: 0.9 }}>
                <TOS to="/termsofservice"><u>Terms of Service</u></TOS>
            </motion.div>
        </SignUpContainer>
    );
}

export default SignUp

import React from 'react';
import {
    BakerCardContainer,
    BakerCardContents,
    BakerCardImageWrapper,
    BakerCardImage,
    BakerBio,
    DisplayName,
    UserVerified
} from './BakeslyCardElements';
import image from '../../images/bakeslycard.png';
import "../animationStyle.css";
import { FaCheckCircle } from "react-icons/fa";

const BakerCard = () => {


    return (
            <BakerCardContainer>
                <DisplayName>
                    <UserVerified><FaCheckCircle color="#5b7329" /></UserVerified>
                </DisplayName>
                <BakerCardImageWrapper>
                    <BakerCardImage src={image} alt="BakerCard" />
                </BakerCardImageWrapper>
                <BakerCardContents>
                    <BakerBio>No results found, tell all the farmers you know to join Tuinure for free!</BakerBio>
                </BakerCardContents>
            </BakerCardContainer>
    )
}

export default BakerCard

import React from 'react';
import useSearch from './useSearch';
import {
    HeroContainer,
    HeroBackground,
    HeroH1,
    HeroP,
    Input,
    InputWrapper,
    IconWrapper,
    HeroContentWrapper,
    InstaLink,
    HomeButton,
    CenterText
} from './HeroSectionElements';
import { BiMap, BiRightArrowAlt } from "react-icons/bi";
import { motion } from "framer-motion";
import { FaInstagram } from "react-icons/fa";
import { setSearch } from "../geocoder/index";
import "../animationStyle.css";

const HeroSection = ({ saveSearch }) => {

    const { handleChange, handleSubmit, _handleKeyDown, values } = useSearch(
        saveSearch
    );

    return (
        <HeroContainer>
            <HeroBackground>
            </HeroBackground>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                className="HomeAnimation"
            >
                <HeroContentWrapper>
                    <CenterText>
                        <HeroH1>Shop straight from farms near you!</HeroH1>
                        <HeroP>Locally produced for you.</HeroP>
                    </CenterText>
                    <InputWrapper onSubmit={handleSubmit}>
                        <IconWrapper>
                            <BiMap size={25} color="white" />
                        </IconWrapper>
                        <div id="geocoder" ref={setSearch} onKeyDown={_handleKeyDown} name="location" value={values.userLocation} onChange={handleChange} required="required" />
                        <HomeButton><motion.button className="HomeMotionButton" whileTap={{ scale: 0.9 }} type="submit"><BiRightArrowAlt color="white" className="HomeButton" /></motion.button></HomeButton>
                    </InputWrapper>
                </HeroContentWrapper>
            </motion.div>
            <motion.div className="InstaButton" whileTap={{ scale: 0.9 }}>
                <a href="https://www.instagram.com/Tuinure/">
                    <FaInstagram size={40} color="#5b7329" />
                </a>
            </motion.div>
        </HeroContainer>
    );
}

export default HeroSection;

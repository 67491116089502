import styled from 'styled-components';

export const MainNav = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: 14px;
  // position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const MainNavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;

  @media (max-width:480px)  {
    display: block;
    justify-content: normal;
    padding: 0 5px;
  }
`;

export const IconWrapper = styled.div`
  text-align: center;
  width: 45px;
  padding-top: 7px;
  float: left;
  height: 40px;
  background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);  border-radius: 10px 0 0 10px;
`;

export const InputWrapper = styled.form`
box-shadow: 0px 1px 5px 0px #0000001c;
border-radius: 10px;
width: 360px;
height: 40px;
margin-top: 20px;
margin-left: auto;
margin-right: auto;
background: #f5f5f5;
display: flex;

@media (max-width:480px)  {
  width: 350px;
  margin-top: 0px;
}

@media (max-width:380px)  {
  width: 330px;
}

@media (max-width:375px)  {
  width: 325px;
}

@media (max-width:340px)  {
  width: 305px;
}

@media (max-width:320px)  {
  width: 280px;
}

@media (max-width:280px)  {
  width: 250px;
}
`;

export const UserLocation = styled.input`
  width: 265px;
  height: 40px;
  border: none;
  padding-left: 10px;
  background: #f5f5f5;
  outline: none;
  padding-right: 10px;
  font-size: 16px;

  @media (max-width:773px)  {
    width: 263px;
  }

  @media (max-width:538px)  {
    width: 227px;
  }
`;

export const HomeWrapper = styled.div`
  
`;

export const MainNavMobileIcon = styled.div`
  display:none;

  @media screen and (max-width: 768px) {
    // display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #5b7329;
  }
`;

export const MainNavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-bottom: 0rem;

  @media screen and (max-width: 768px) {
    // display: none;
  }

  @media (max-width:480px)  {
    margin-top: -115px;
    margin-left: 113px;
  }


  @media (max-width:430px)  {
    margin-left: 200px;
  }

  @media (max-width:414px)  {
    margin-left: 185px;
  }

  @media (max-width:393px)  {
    margin-left: 170px;
  }

  @media (max-width:390px)  {
    margin-left: 165px;
  }

  @media (max-width:375px)  {
    margin-left: 150px;
  }

  @media (max-width:320px)  {
    margin-left: 105px;
  }
`;

export const LocationPickerWrapper = styled.div`
  
`;

export const HomeButton = styled.div`
   
`;

export const ProductNumbers = styled.div`
  position: absolute;
  bottom: 7px;
  right: 0;
  color: #5b7329;
  width: 25px;
  font-size: 12px;
  height: 25px;
  text-align: center;
`;
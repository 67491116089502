import styled from 'styled-components';

export const CheckOutContainer = styled.div`
    display: flex;
    margin-top: 30px;
    margin: auto;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 20px;

    @media screen and (max-width: 1024px) {
        width: 555px;
        display: block;
    }

    @media screen and (max-width: 768px) {
        width: 720px;
        display: grid;
    }

    @media screen and (max-width: 480px) {
        width: auto;
        padding-top: 0px;
    }
`;

export const CheckOutWrapper = styled.form`
    margin-bottom: 50px;  
    
    @media screen and (max-width: 1024px) {
        width: 556px;
    }

    @media screen and (max-width: 768px) {
        margin: auto;
        width: 576px;

    }

    @media screen and (max-width: 480px) {
        width: 275px;
        margin-top: 20px;
    }
`;

export const OrderListWrapper = styled.div`
    margin-left: 30px;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px #0000001c;
    border-radius: 10px;
    height: fit-content;

    @media screen and (max-width: 1024px) {
        width: 405px;
        height: fit-content;
        display: flow-root;
        margin: auto;
    }

    @media screen and (max-width: 768px) {
        width: 600px;
    }

    @media screen and (max-width: 480px) {
        width: fit-content;
        margin-bottom: 60px;
    }

    @media screen and (max-width: 320px) {
        padding: 10px;
    }
`;

export const InputWrapper = styled.div`
    width: fit-content;
    float: left;

    @media screen and (max-width: 480px) {
        float: none;
        margin-left: 0px;
    }
`;

export const Textarea = styled.textarea`
    width: 268px;
    height: 60px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    resize: none;
    padding: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const CheckOutTitle = styled.div`
    font-size: 25px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;
`;

export const CheckOutListTitle = styled.div`
    font-size: 20px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;
`;

export const Label = styled.div`
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 8px;
`;

export const Input = styled.input`
    width: 268px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputFieldWrapper = styled.div`
    text-align: left;
    padding: 5px;
`;

export const CheckboxDiv = styled.div`
    display: flex;
    float: left;
    padding: 10px;
`;

export const CheckboxWrapper = styled.div`
    height: 40px;
`;

export const InputCheckbox = styled.input`
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 10px;
    outline: none;
`;

export const LabelCheckbox = styled.div`
    margin-left: 4px;
    font-size: 14px;
    padding: 4px;
`;

export const SelectInput = styled.select`
    width: 268px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputStreetName = styled.input`
    width: 180px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputHouseNumber = styled.input`
    width: 80px;
    height: 40px;
    border: none;
    margin-left: 8px;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const TotalCostWrapper = styled.table`
    margin-top: 30px;
    width: 100%;
    font-size: 14px;

    tbody, td, tfoot, th, thead, tr {
        border:none;
    }
`;

export const ServiceCost = styled.div`
    color: green;  
`;

export const TransactionCost = styled.div`
    color: green;   
`;

export const DeliveryCost = styled.div`
    margin-top: 5px;
`;

export const TotalCost = styled.div`
    margin-top: 5px;
`;

export const MinPrice = styled.div`
    margin-bottom: 10px;
`;

export const CheckOutLine = styled.div`
    height: 2px;
    width: 100%;
    margin: auto;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
`;

export const StockWrapper = styled.div`
    width: 268px;
    line-break: anywhere;  
`;
import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const BakerCardLink = styled(LinkRouter)`
    text-decoration: none;
    color: black;
    position: absolute;
    height: inherit;

    &:hover {
        color: black;
    }
`;

export const BakerCardContainer = styled.div`
    box-shadow: 0px 1px 5px 0px #0000001c;
    border-radius: 10px;
    position: relative;
    display: inline-table;
    margin: 10px;
    width: 300px;
    height: 450px;
    background: white;
`;

export const BakerCardContents = styled.div`
    margin: 10px;
`;

export const BakerCardImageWrapper = styled.div`
    height: 225px;
    background: white;
`;

export const BakerCardImage = styled.img`
object-fit: cover;
    width: 300px;
    height: inherit;
    display: block;
`;

export const BakerNamer = styled.div`
    font-size: 18px;
`;

export const BakerReview = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const BakerBio = styled.div`
    font-size: 14px;
    margin-top: 5px;
    width: 280px;
    overflow-wrap: break-word;
`;

export const UserRatings = styled.div`
    margin-top: 1px;
    margin-left: 5px;
    font-size: 12px;
`;

export const BakerTime = styled.div`
    font-size: 12px;
`;

export const MinOrder = styled.div`
    font-size: 12px;

`;

export const CardLine = styled.div`
    height: 2px;
    width: 100%;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
`;

export const DisplayName = styled.div`
    margin: 10px;
`;

export const UserVerified = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
`;

export const Closed = styled.div`
    color: red;
    font-weight: 500;;
`;

export const Open = styled.div`
    color: black;
`;
import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const SearchBarNavigation = styled.div`
    display: none;

    @media (max-width:480px)  {
        // display: block;
        box-shadow: 0px 1px 5px 0px #0000001c;
        border-radius: 10px;
        width: 360px;
        height: 40px;
        margin-top: 20px;
        text-align: right;
        margin-left: auto;
        margin-right: auto;
        background: #f5f5f5;
        width: 350px;
    }

    @media (max-width:380px)  {
        width: 330px;
        // font-size: 13px;
    }

    @media (max-width:375px)  {
        width: 325px;
    }

    @media (max-width:340px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 280px;
        // font-size: 11px;
        // height: 35px;
    }
`;

export const IconWrapper = styled.div`
    text-align: center;
    width: 45px;
    padding-top: 7px;
    float: left;
    height: 40px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    
    border-radius: 10px 0 0 10px;

    // @media (max-width:320px)  {
    //     width: 40px;
    //     height: 35px;
    //     padding-top: 2px;
    // }
`;

export const UserLocation = styled.input`
    width: 265px;
    height: 40px;
    border: none;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 16px;

    @media (max-width:480px)  {
        width: 255px;
    }

    @media (max-width:380px)  {
        width: 235px;
    }

    @media (max-width:375px)  {
        width: 230px;
    }

    @media (max-width:340px)  {
        width: 210px;
    }

    @media (max-width:320px)  {
        width: 185px;
    }
`;

export const ExploreButton = styled(LinkRouter)`
   
`;

export const InputWrapper = styled.form`
box-shadow: 0px 1px 5px 0px #0000001c;
border-radius: 10px;
width: 360px;
height: 40px;
margin-top: 20px;
margin-left: auto;
margin-right: auto;
background: #f5f5f5;
display: flex;

//   @media (max-width:480px)  {
    display: none;
//   }
`;

export const HomeButton = styled.div`
   
`;
import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const ConfirmTextContainer = styled.div`
    width: 50%;
    border-radius: 10px;
    height: fit-content;
    position: absolute;
    bottom: 10px;
    background: white;
    padding: 10px;
    left: 10px;
    z-index: 1;
`;

export const ConfirmEmailText = styled.div`
    font-size: 15px;
`;

export const ConfirmEmailsIcons = styled.div`

`;

export const TOS = styled(LinkRouter)`
  text-decoration: none;
  cursor: pointer;
  color: #d3d3d3;

  @media (max-width:320px)  {
    font-size: 12px;
  }

  &:hover {
    color: #5b7329;
  }
`;
import { useState } from 'react';

const useForm = (validate) => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    businessName: '',
    phoneNumber: '',
    address: '',
    description: '',
    socialOne: '',
    website: '', 
    socialTwo: '',
    tos: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    try {
      
      setErrors(validate(values));

      const baseURL =
        process.env.NODE_ENV === "production"
          ? "/api/"
          : "http://localhost:3001/api/";

      const sendResponse = async () => {

        await fetch(baseURL+'signup', {
          credentials: 'same-origin',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.json()).then(data => {
          console.log(data);
          if (data === "User already exist") {
            values.emailAddress = "User already exist";
            setErrors(validate(values));
          } else if (data === "User request already received") {
            values.emailAddress = "User request already received";
            setErrors(validate(values));
          } else if (data === "Account is being verified") {
            values.emailAddress = "Account is being verified";
            setErrors(validate(values));
          } else if (data === "User banned") {
            values.emailAddress = "User banned";
            setErrors(validate(values));
          } else if (data !== "User already exist" && data !== "User request already received" && data !== "User request already received") {
              window.location.replace("https://Tuinure.com/checkmail");
          }
        }).catch(error => console.error('Error:', error));
      }

      if (Object.keys(validate(values)).length === 0) {
        sendResponse();
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, errors };
}

export default useForm;
import React from 'react';
import {FaBars} from 'react-icons/fa';
import Logo from '../../images/logov2.png';
import {
  Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon, 
  NavMenu,
  NavLink
} from './NavbarElements';
import { motion } from "framer-motion";
import "../animationStyle.css";

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <motion.div className="LogoAnimation" whileTap={{ scale: 0.9 }}>
            <NavLogo to= "/"> <img src={Logo} width="100px" alt="Tuinure" /> </NavLogo>
          </motion.div>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
                <NavLink to="/about">About us</NavLink>
              </motion.div>
              <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
                <NavLink to="/signup">Join the platform!</NavLink>
              </motion.div>
              <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
                <NavLink to="/signin">Sign in</NavLink>
              </motion.div>
            </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
import styled from 'styled-components';
import Background from '../../images/peter-wendt--r5KSMkyoSc-unsplash.jpeg';
import { Link as LinkRouter } from 'react-router-dom';

export const HeroContainer = styled.div`
    width: 100%;   
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const CenterText = styled.div`
    text-align: center;
`;

export const HeroBackground = styled.div`
    width: 100%;
    height: 100%;
    box-shadow: 0px 1px 0px 5px #00000075;
    bottom: 0;
    clip-path: polygon(0 0, 19% 0, 40% 0, 59% 100%, 38% 100%, 0 100%, 0% 80%, 0% 20%);
    z-index: -1;
    position: absolute;
    background: url(${Background}); // image size new suitable image and size 
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    animation: fadeIn ease 0.5s;
    background-position: right top 20%;

    @keyframes fadeIn {
        0% { 
            opacity: 0; 
        } 
        100% { 
            opacity: 1; 
         } 
     } 

    @media screen and (max-width: 1024px) {
        clip-path: polygon(23% 100%, 0 100%, 0 80%, 100% 54%, 100% 100%, 79% 100%, 60% 100%, 40% 100%);
        background-position: right top 0;
    }

    @media screen and (max-width: 786px) {
        clip-path: polygon(23% 100%, 0 100%, 0 83%, 100% 60%, 100% 100%, 79% 100%, 60% 100%, 40% 100%);
    }

    @media screen and (max-width: 375px) {
        clip-path: polygon(23% 100%, 0 100%, 0 83%, 100% 68%, 100% 100%, 79% 100%, 60% 100%, 40% 100%);
    }

    @media (max-width:480px)  {
        clip-path: polygon(23% 100%, 0 100%, 0 83%, 100% 74%, 100% 100%, 79% 100%, 60% 100%, 40% 100%);
    }
`;

export const HeroContentWrapper = styled.div`
    height: fit-content;
    width: fit-content;
    margin: auto;
    display: table-cell;
    vertical-align: middle;
`;

export const HeroH1 = styled.h1`
    font-size: 45px;
    width: 500px;
    padding-bottom: 20px;
    margin: auto;
    font-family: Inter;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300;

    @media (max-width:480px)  {
        font-size: 34px;
        width: 370px;
    }

    @media (max-width:375px)  {
        font-size: 30px;
        width: 340px;
    }

    @media (max-width:320px)  {
        font-size: 25px;
        width: 285px;
    }

    @media (max-width: 280px) {
        width: 280px;
      }
`;

export const HeroP = styled.p`
    color: #bbbbbb;
    font-size: 17px;
    width: 360px;
    margin-top: 15px;
    margin: auto;

    @media (max-width:375px)  {
        width: 250px;
        font-size: 14px;
    }

    @media (max-width:320px)  {
        font-size: 12px;
    }
`;

export const InputWrapper = styled.form`
    box-shadow: 0px 1px 5px 0px #0000001c;
    border-radius: 10px;
    width: 360px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background: #f5f5f5;
    display: flex;

    @media (max-width:480px)  {
        width: 350px;
    }

    @media (max-width:380px)  {
        width: 330px;
    }

    @media (max-width:375px)  {
        width: 325px;
    }

    @media (max-width:340px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 280px;
    }

    @media (max-width:280px)  {
        width: 250px;
    }
`;

export const IconWrapper = styled.div`
    text-align: center;
    width: 45px;
    padding-top: 7px;
    float: left;
    height: 40px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    border-radius: 10px 0 0 10px;
`;

export const Input = styled.input`
    width: 265px;
    height: 40px;
    border: none;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 16px;

    @media (max-width:480px)  {
        width: 255px;
    }

    @media (max-width:380px)  {
        width: 235px;
    }

    @media (max-width:375px)  {
        width: 230px;
    }

    @media (max-width:340px)  {
        width: 210px;
    }

    @media (max-width:320px)  {
        width: 185px;
    }
`;

export const InstaLink = styled(LinkRouter)`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #5b7329;
  }
`;

export const HomeButton = styled.div`
   
`;
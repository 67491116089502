import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'

export const TopbarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
    color: #5b7329;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const TopbarWrapper = styled.div`
    color: #fff;
`;

export const TopbarMenu = styled.ul`
    padding-left: 0px;

    // @media screen and (max-width: 480px) {
    //   grid-template-rows: repeat(6, 60px);
    // }

    // @media screen and (max-width: 320px) {
    //     padding-left: 0px;
    //     margin-top: 100px;
    //   }

    //   @media screen and (max-width: 375px) {
    //     padding-left: 0px;
    //     margin-top: 100px;
    //   }

    //   @media screen and (max-width: 414px) {
    //     padding-left: 0px;
    //     margin-top: 100px;
    //   }

    //   @media screen and (max-width: 768px) {
    //     padding-left: 0px;
    //     margin-top: 100px;
    //   }
`;

export const TopbarLink = styled(LinkRouter)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #5b7329;
    cursor: pointer;
    padding: 30px;

    &:hover {
      color: #bdd7f1;  
      transition: 0.2s ease-in-out;
    }
`;




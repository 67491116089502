import { useState, useContext } from 'react';
import { geoLocation, geoPlace } from "../geocoder/index";
import { UsersContext } from '../../context/UsersContext';

const useForm = (validate, setAuth) => {
  const [values, setValues] = useState({
    profileimage: '',
    firstname: '',
    lastname: '',
    businessname: '',
    emailaddress: '',
    phonenumber: '',
    bio: '',
    location: '',
    streetname: '',
    streetnumber: '',
    place: '',
    sellingdistance: '',
    currency: '',
    mindeliveryprice: '',
    password: '',
    password2: '',
    // paypalemail: '',
    mon_open: '',
    mon_closed: '',
    tue_open: '',
    tue_closed: '',
    wed_open: '',
    wed_closed: '',
    thu_open: '',
    thu_closed: '',
    fri_open: '',
    fri_closed: '',
    sat_open: '',
    sat_closed: '',
    sun_open: '',
    sun_closed: '',
    tos: '',
    username: '',
    mintime: '',
    maxtime: '',
    delivery: '',
    pickup: '',
  });
  const [selectedImage, setSelectedImage] = useState();
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isFailed, setFailed] = useState(false);

  const { userAuth, setUserAuth } = useContext(UsersContext);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    setSelectedImage({
      ...selectedImage,
      file
    });
  }

  const imagePreviewHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const inputId = document.getElementById("ProfileImage");
    const imageId = document.getElementById("UserImage");

    try {
      if (file === undefined) {
        imageId.src = null;
        inputId.value = null;
      } else {
        reader.onloadend = () => {
          baseImage(reader.result)
        };

        reader.readAsDataURL(file);

        const baseImage = (encodedImage) => {
          imageId.src = encodedImage;
        }

      // } else {
      //   alert("Selected image file was to big, max. 1.5MB");
      //   imageId.src = null;
      //   inputId.value = null;
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    try {

      if (geoLocation !== undefined && geoPlace !== undefined) {
        values.location = geoLocation;
        values.place = geoPlace;
        } else {
          values.location = "";
          values.place = "";
        } 
    
      setErrors(validate(values, selectedImage));
      
      const baseURL =
        process.env.NODE_ENV === "production"
          ? "/api/"
          : "http://localhost:3001/api/";

      const sendResponse = async (baseImage) => {

        values.profileimage = baseImage;

      await fetch(baseURL+'completesignup', {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.json()).then(data => {
          if (data === "Email is not registered") {
            values.emailaddress = "Email is not registered";
            setErrors(validate(values, selectedImage));
            setAuth(false);
            setLoading(false);
          } else if (data === "User already exist") {
            values.emailaddress = "User already exist";
            setErrors(validate(values, selectedImage));
            setAuth(false);
            setLoading(false);
          } else if (data === "Username exist") {
            values.username = "Username already exist";
            setErrors(validate(values, selectedImage));
            setAuth(false);
            setLoading(false);
          } 
          else if (data !== "Email is not registered" && data !== "User already exist") {            
            if (data.accessToken !== 0) {
              setUserAuth({...userAuth, accessToken: data.accessToken, user: data.userid, username: data.username });
              localStorage.setItem('searchData', JSON.stringify(values.location));
              localStorage.setItem('searchInput', JSON.stringify(values.place));
              setAuth(true);
            } else {
              setAuth(false);
              setLoading(false);
            }
          }
        }).catch(error => console.error('Error:', error));
      }

      if (Object.keys(validate(values, selectedImage)).length === 0) {
        setLoading(true);
        const reader = new FileReader();
        reader.readAsDataURL(selectedImage.file);
        reader.onloadend = () => {
          sendResponse(reader.result);
        };
      } else {
        setLoading(false);
        setAuth(false);
      }

      setTimeout(function(){
        setFailed(true);
        setLoading(false);
        }, 15000);
    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, imagePreviewHandler, handleFileChange, handleSubmit, values, selectedImage, isLoading, setLoading, errors, isFailed, setFailed };
};

export default useForm;
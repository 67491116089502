import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const ReviewContainer = styled.div`

`;

export const ReviewWrapper = styled.div`
    position: absolute;
    margin: auto;
    width: fit-content;
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 480px) {
        width: 90%;
    }
`;

export const ReviewTitle = styled.div`
    font-size: 33px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;
`;

export const ReviewInput = styled.textarea`
    width: 378px;
    height: 135px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;  
    font-size: 19px;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const ReviewButton = styled.div`

`;

export const RatingWrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const RatingNameProduct = styled.span`
    font-size: 16px;
    margin-left: 15px;
    margin-top: 6px;
    position: absolute;
`;

export const TOS = styled(LinkRouter)`
  text-decoration: none;
  cursor: pointer;
  color: #bfbfbf;

  &:hover {
    color: #5b7329;
  }
`;
import React, { useEffect, useState } from 'react';
import {
    CheckOutContainer,
    CheckOutWrapper,
    OrderListWrapper,
    InputWrapper,
    CheckOutTitle,
    CheckOutListTitle,
    Label,
    Input,
    InputFieldWrapper,
    CheckboxWrapper,
    CheckboxDiv,
    InputCheckbox,
    LabelCheckbox,
    SelectInput,
    InputStreetName,
    InputHouseNumber,
    Textarea,
    TotalCostWrapper,
    ServiceCost,
    DeliveryCost,
    TransactionCost,
    TotalCost,
    CheckOutLine,
    MinPrice,
    StockWrapper
} from './CheckOutElements';
import CheckOutProduct from '../CheckOutProduct';
import { motion } from "framer-motion";
import UserData from '../../API/UserFinder';
import currencyArray from '../Currency/currency.json';
import validate from './validate';
import useForm from './useForm';
import "../animationStyle.css";
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Elements } from '@stripe/react-stripe-js';
import CheckOutForm from '../CheckOutForm';
import { FaInfo } from 'react-icons/fa';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CheckOut = () => {

    const [productData, setProductData] = useState([]);
    const [priceCalc, setPriceCalc] = useState([]);
    const [deliveryOption, setDeliveryOption] = useState(false);
    const [pickupOption, setPickUpOption] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const [currency, setCurrency] = useState();
    const [minDeliveryPrice, setMinDeliveryPrice] = useState("");
    const [deliveryCost, setDeliveryCost] = useState("");
    const [totalCost, setTotalCost] = useState("");
    const [transactionCost, setTransactionCost] = useState("");
    const [finalCost, setFinalCost] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [japan, setJapan] = useState("");

    // const [stripePromise, setStripePromise] = useState(null);
    // const [clientSecret, setClientSecret] = useState("");

    const userProducts = Object.keys(sessionStorage);
    const userProductsArray = [];
    const productsArray = [];
    const deliveryData = [];
    const pickupData = [];
    const deliveryCostNumberArray = [];
    const totalArray = [];

    userProductsArray.push(userProducts)

    userProductsArray[0].map(product => { productsArray.push(JSON.parse(sessionStorage.getItem(product))); })

    const { handleChange, handleSubmit, values, errors, isLoading, setLoading, open, setOpen, handleClose, clientSecret, setClientSecret, stripePromise, setStripePromise, stock, setStock } = useForm(
        validate,
        productsArray
    );

    const checkHandler = () => {
        setIsChecked(!isChecked);
    }

    if (isChecked === false) {
        values.tos = false;
    } else if (isChecked === true) {
        values.tos = true;
    }

    if (productsArray.length === 0) {
        values.empty = true;
    }

    const setProducts = () => {
        if (productsArray.length > 0) {
            return productsArray.map(product => <CheckOutProduct key={product.product} product={product} />)
        } else {
            return <p>Cart is empty!</p>
        }
    }

    useEffect(() => {

        window.scrollTo(0, 0)

        const fetchProductCalc = async () => {
            try {
                const getProductCalc = await UserData.get('/priceCalc', {
                    headers: {
                        products: JSON.stringify(productsArray)
                    }
                });

                setPriceCalc(getProductCalc.data)

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchProductCalc();

        const fetchProductData = async (e) => {
            try {
                const getProduct = await UserData.get('/cart', {
                    headers: {
                        product: e
                    }
                });

                setProductData((prevState) => {
                    let result = [...prevState, getProduct.data.product];
                    return result;
                });

            } catch (err) {
                console.error(err.message);
            }
        };

        productsArray.map(product => {
            fetchProductData(product.product);

            deliveryData.push(product.delivery)
            pickupData.push(product.pickup)
        })

        let checkDelivery = deliveryData.every(Boolean);
        let checkPickup = pickupData.every(Boolean);

        if (checkDelivery == false) {
            setDeliveryOption(true)
        } else if (checkPickup === false) {
            setPickUpOption(true)
        }
    }, []);

    useEffect(() => {

        if (productData.length !== 0) {
            productData.map(price => {
                deliveryCostNumberArray.push(Number(price[0].deliveryprice));

                setCurrency(currencyArray[price[0].currency][0]);

                setJapan((price[0].currency).toLowerCase());

                setMinDeliveryPrice(price[0].mindeliveryprice);

                productsArray.map(quantity => {
                    if (quantity.product === price[0].productid) {
                        totalArray.push(price[0].productprice * quantity.quantity);
                    }
                })
            })
        } else {
            setCurrency("")
        }

    }, [productData])

    useEffect(() => {

        if (japan !== 'jpy') {
            setTotalCost((Number(priceCalc.calcTotal)).toFixed(2))
            setDeliveryCost(Number((priceCalc.delivCost)).toFixed(2))
            setTransactionCost(Number((priceCalc.appFee)).toFixed(2))
        } else {
            setTotalCost(Number((priceCalc.calcTotal)).toFixed(0))
            setDeliveryCost(Number((priceCalc.delivCost)).toFixed(0))
            setTransactionCost(Number((priceCalc.appFee)).toFixed(0))
        }

    }, [japan, priceCalc])

    useEffect(() => {

        if (Number(minDeliveryPrice) > Number(totalCost) && values.service === "1") {
            values.minDeliv = 0;
        } else {
            values.minDeliv = minDeliveryPrice;
        }

        if (values.service === "1") {
            if ((japan).toLowerCase() !== 'jpy') {
                setFinalCost((Number(deliveryCost) + Number(totalCost)).toFixed(2));
            } else {
                setFinalCost((Number(deliveryCost) + Number(totalCost)).toFixed(0));
            }
            setDelivery(true)
        } else {
            if (japan !== 'jpy') {
                setFinalCost((Number(totalCost)).toFixed(2));
            } else {
                setFinalCost((Number(totalCost)).toFixed(0));
            }
            setDelivery(false)
        }
    }, [values.service, deliveryCost, totalCost, japan])

    return (
        <CheckOutContainer>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
            >
                <CheckOutWrapper onSubmit={handleSubmit}>
                    <CheckOutTitle>Check Out</CheckOutTitle>
                    <InputWrapper>
                        <InputFieldWrapper>
                            <Label>First name*</Label>
                            <Input
                                type="text"
                                required
                                name="firstname"
                                placeholder="Enter your first name"
                                value={values.firstname}
                                onChange={handleChange}
                            />
                            {errors.firstname && <p className="Errorstyle">{errors.firstname}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <Label>Last name*</Label>
                            <Input
                                type="text"
                                required
                                name="lastname"
                                placeholder="Enter your last name"
                                value={values.lastname}
                                onChange={handleChange}
                            />
                            {errors.lastname && <p className="Errorstyle">{errors.lastname}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <Label>Email*</Label>
                            <Input
                                type="email"
                                required
                                name="email"
                                placeholder="Enter your email"
                                value={values.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p className="Errorstyle">{errors.email}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <Label>Phone number*</Label>
                            <Input
                                type="tel"
                                required
                                name="phone"
                                placeholder="Enter your phone number"
                                value={values.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && <p className="Errorstyle">{errors.phone}</p>}
                        </InputFieldWrapper>
                    </InputWrapper>
                    <InputWrapper className="CheckoutRow2">
                        <InputFieldWrapper>
                            <Label>Choose available option*</Label>
                            <SelectInput
                                name="service"
                                value={values.service}
                                onChange={handleChange}
                                required
                            >
                                <option value="" default>Select service</option>
                                <option value="1" disabled={deliveryOption}>Delivery (+{currency + deliveryCost})</option>
                                <option value="2" disabled={pickupOption}>Pick-up</option>
                            </SelectInput>
                            {errors.service && <p className="Errorstyle">{errors.service}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <Label>Zipcode*</Label>
                            <Input
                                type="text"
                                required
                                name="zipcode"
                                placeholder="Enter your zipcode"
                                value={values.zipcode}
                                onChange={handleChange}
                            />
                            {errors.zipcode && <p className="Errorstyle">{errors.zipcode}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <Label>Address*</Label>
                            <InputStreetName
                                placeholder="Street name"
                                type="text"
                                name="streetname"
                                required
                                value={values.streetname}
                                onChange={handleChange}
                            />
                            <InputHouseNumber
                                placeholder="Number"
                                type="text"
                                required
                                name="streetnumber"
                                value={values.streetnumber}
                                onChange={handleChange}
                            />
                            {errors.streetname && <p className="Errorstyle">{errors.streetname}</p>}
                            {errors.streetnumber && <p className="Errorstyle">{errors.streetnumber}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <Label>Add a note/request</Label>
                            <Textarea
                                name="note"
                                value={values.note}
                                onChange={handleChange}
                                maxLength="500"
                                placeholder="Enter your requests"
                            />
                            {errors.note && <p className="Errorstyle">{errors.note}</p>}
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <CheckboxWrapper>
                                <CheckboxDiv>
                                    <InputCheckbox
                                        type="checkbox"
                                        name="tos"
                                        required
                                        value={values.tos}
                                        checked={isChecked}
                                        onChange={checkHandler}
                                    />
                                    <LabelCheckbox>Accept the <a href="http://localhost:3001/termsofservice">terms of service</a>.*</LabelCheckbox></CheckboxDiv>
                                {errors.tos && <p className="Errorstyle">{errors.tos}</p>}
                            </CheckboxWrapper>
                        </InputFieldWrapper>
                        <motion.button variant="outlined" className="ConfirmCheckout" whileTap={{ scale: 0.9 }} type="submit">Checkout</motion.button>
                        {errors.empty && <p className="Errorstyle">{errors.empty}</p>}
                        {errors.minDeliv && <p className="Errorstyle">{errors.minDeliv}</p>}
                        {errors.stockcheck && <p className="Errorstyle">{errors.stockcheck}</p>}
                        {errors.closed && <p className="Errorstyle">{errors.closed}</p>}
                        <StockWrapper>
                            {stock}
                        </StockWrapper>
                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition}
                        >
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="#757575"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                            {clientSecret && stripePromise && (
                                <Elements stripe={stripePromise} options={{ clientSecret }}>
                                    <CheckOutForm />
                                </Elements>
                            )}
                        </Dialog>
                    </InputWrapper>
                </CheckOutWrapper>
            </motion.div>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                // className="cheoutdivani"
            >
                <OrderListWrapper>
                    <CheckOutListTitle>Order Summary:</CheckOutListTitle>
                    {setProducts()}
                    <br />
                    <CheckOutLine />
                    <TotalCostWrapper>
                        <tbody>
                            {!delivery ? (
                                <></>
                            ) : (
                                <tr>
                                    <td><MinPrice>Min. price to order for delivery:</MinPrice></td>
                                    <td>{currency + minDeliveryPrice}</td>
                                </tr>
                            )}
                            <tr>
                                <td>

                                    <TransactionCost>
                                        {/* <span className="infocost">
                                            <FaInfo />
                                            <span className="tooltiptext">The service cost for operations of Tuinure is non-refundable.</span>
                                        </span> */}
                                        Service cost:<span></span>
                                    </TransactionCost>
                                </td>
                                <td>{currency + transactionCost}</td>
                            </tr>
                            {!delivery ? (
                                <></>
                            ) : (
                                <tr>
                                    <td><DeliveryCost>Delivery cost:<span></span></DeliveryCost></td>
                                    <td>{currency + deliveryCost}</td>
                                </tr>
                            )}
                            <tr>
                                <td><TotalCost>Total cost:<span></span></TotalCost></td>
                                <td>{currency + finalCost}</td>
                            </tr>
                        </tbody>
                    </TotalCostWrapper>
                </OrderListWrapper>
            </motion.div>
        </CheckOutContainer>
    )
}

export default CheckOut

import React, { useState } from 'react';

const useForm = (validate, rating, hover, token) => {
  const [values, setValues] = useState({
    review: '',
    rating: '',
  });
  const [errors, setErrors] = useState({});

  // get order datails, shop, buyer and products, show products +3 for example when more than 1 
  // add review middle ware validation
  // server side :token is post review, need get for order data

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    try {

      const calcrating = rating !== null && [hover !== -1 ? hover : rating];

      values.rating = calcrating[0];
      
      setErrors(validate(values));

      const baseURL =
        process.env.NODE_ENV === "production"
          ? "/api/"
          : "http://localhost:3001/api/";

      const sendResponse = async () => {
        await fetch(baseURL+'review/'+token, {
          credentials: 'same-origin',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.json()).then(data => {
          if (data === "Success") {
            window.location.replace("/");
          } else if (data === "Already reviewed") {
            values.review = "Review already exist";
            setErrors(validate(values));
          } else {
            values.review = "Review expired";
            setErrors(validate(values));
          } 
        }).catch(error => console.error('Error:', error));
      }

      if (Object.keys(validate(values)).length === 0) {
        sendResponse();
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, errors };
}

export default useForm;
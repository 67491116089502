import React, { useState, useEffect } from 'react'
import {
    ExploreContainer,
    ExploreCardWrapper,
    ExploreSearchBox,
    ExploreSearchBoxWrapper,
    TitleTagExplore
} from './ExploreElements';
import MobileAddressBar from '../MobileAddressBar';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import "../animationStyle.css";
import BakerCard from '../BakerCard';
import BakeslyCard from '../BakeslyCard';
import UserData from '../../API/UserFinder';
import { motion } from "framer-motion";
import * as turf from '@turf/turf'
import spinner from '../../images/Spinner-1s-200px.gif';

const Explore = () => {

    const [shops, setShops] = useState([]);
    const [shopsLoc, setShopsLoc] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false);

    const search = JSON.parse(localStorage.getItem('searchData'));

    const baseLon = Number(search.split(',')[0]);
    const baselat = Number(search.split(',')[1]);

    var buyerradius = 25;
    let buyerlon = baseLon, buyerlat = baselat
    var buyercenter = [buyerlon, buyerlat];
    var buyeroptions = { steps: 50, units: "kilometers" };
    var buyerpoint = turf.point(buyercenter);
    var buyercircle = turf.circle(buyercenter, buyerradius, buyeroptions);

    var searchWithin = turf.polygon([buyercircle.geometry.coordinates[0]]);

    const selectedtagsArray = [];
    const shopMarker = [];
    const shopsWithinUserArray = [];
    const userWithinShopsArray = [];

    useEffect(() => {

        window.scrollTo(0, 0)

        const fetchShopsLoc = async () => {
            try {
                const getShopsLoc = await UserData.get('/mapdata');

                setShopsLoc(getShopsLoc.data.data.shops);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchShopsLoc();

        const fetchTags = async () => {
            try {
                const getTag = await UserData.get('/tags');
                setTags(getTag.data.data.tag)
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchTags();

    }, [setShopsLoc, selectedTags]);

    useEffect(() => {

        if (shopsLoc.length > 0) {
            shopsLoc.map(shop => {
                var shopradius = Number(shop.sellingdistance);
                let shoplon = Number(shop.location.split(',')[0]), shoplat = Number(shop.location.split(',')[1])
                var shopcenter = [shoplon, shoplat];
                var shopoptions = { steps: 50, units: "meters", name: shop.username, id: shop.userid };
                var shopcircle = turf.circle(shopcenter, shopradius, shopoptions);
                var shopPoint = turf.point(shopcenter, shopoptions);
                var shopPoly = turf.polygon([shopcircle.geometry.coordinates[0]]);

                shopMarker.push([shopPoint, shopcircle, shopPoly]);
            })
        }

        shopMarker.map(shop => {
            var pointsWithinBuyer = turf.pointsWithinPolygon(shop[0], searchWithin);
            var pointWithinSeller = turf.pointsWithinPolygon(buyerpoint, shop[2]);

            if (pointsWithinBuyer.features[0] !== undefined) {
                shopsWithinUserArray.push(pointsWithinBuyer.features[0].properties.id);
            }

            if (pointWithinSeller.features[0] !== undefined) {
                userWithinShopsArray.push(shop[0].properties.id);
            }
        })

        selectedTags.map(selectedtag => {
            // setSelectedtagsArray(current => [...current, selectedtag.tagid])
            selectedtagsArray.push(selectedtag.tagid)
        })

        const fetchResults = async () => {
            try {
                await UserData.get('/shops', {
                    headers: {
                        sellers: JSON.stringify(userWithinShopsArray),
                        buyers: JSON.stringify(shopsWithinUserArray),
                        tags: JSON.stringify(selectedtagsArray)
                    }
                }).then(data => {
                    setShops(data.data)
                });

            } catch (err) {
                console.error(err.message);
            }
        }

        if (userWithinShopsArray !== undefined && userWithinShopsArray !== undefined && userWithinShopsArray.length > 0 && userWithinShopsArray.length > 0) {
            fetchResults();
        }

    }, [shopsLoc, selectedTags]);

    const setCards = () => {
        if (shops.results !== undefined && shops.results.length > 0) {
            return shops.results.map(shop => <BakerCard key={shop.username} shops={shop} delivery={shops.inSellerRange} open={shop.open} />);
        } else {
            setTimeout(() => {
                if (loading) {
                    setShowLoader(true);
                }
            }, 3000)

            if (showLoader) {
                return <BakeslyCard />
            } else {
                return <img src={spinner} alt="Loading" width="48" height="48" />
            }
        }
    }

    return (
        <ExploreContainer>
            <MobileAddressBar></MobileAddressBar>
            <ExploreSearchBoxWrapper>
                <TitleTagExplore>Explore</TitleTagExplore>
                <ExploreSearchBox>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        onChange={(event, value) => {
                            event.preventDefault()
                            setSelectedTags(value)
                        }}
                        options={tags}
                        // getOptionSelected={(option, value) => option.tags === value.tags}
                        isOptionEqualToValue={(option, value) => option.tags === value.tags}
                        getOptionLabel={(option) => option.tags}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Browse tags: Fruits, Vegetables, etc."
                                placeholder="Browse tags"
                            />
                        )}
                    />
                </ExploreSearchBox>
            </ExploreSearchBoxWrapper>
            <ExploreCardWrapper>
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    {setCards()}
                </motion.div>
            </ExploreCardWrapper>
        </ExploreContainer>
    )
}

export default Explore

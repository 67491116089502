import styled from 'styled-components';

export const ExploreContainer = styled.div`

`;

export const TitleTagExplore = styled.div`
    font-size: 25px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;
`;

export const ExploreCardWrapper = styled.div`
    margin: auto;
    width: 960px;
    padding-bottom: 20px;

    @media screen and (max-width: 1024px) {
        width: 640px;
    }

    @media (max-width:480px)  {
        width: 320px;
    }

    @media (max-width:320px)  {
        width: 100%;
    }
`;

export const ExploreSearchBox = styled.div`
    margin-top: 40px;
    padding-bottom: 60px;


    @media screen and (max-width: 768px) {
        padding-bottom: 50px;
    }

    @media (max-width:480px)  {
        margin-top: 30px;
        padding-bottom: 40px;
    }
`;

export const ExploreSearchBoxWrapper = styled.div`
    width: 70%;
    margin: auto;
    margin-top: 40px;

    @media (max-width:480px)  {
        width: 280px;
        margin-top: 60px;
    }

    @media (max-width:375px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 280px;
    }
`;


import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const SignInContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: table;
`;

export const SignInLogo = styled.img`
    margin: auto;
    width: 135px;
`;

export const UsernameInputWrapper = styled.div`
    width: 330px;
    margin-top: 35px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;

    @media (max-width:480px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 270px;
    }
`;

export const UsernameIconWrapper = styled.div`
    text-align: center;
    width: 45px;
    padding-top: 7px;
    background: white;
    float: left;
    height: 40px;
`;

export const PasswordInputWrapper = styled.div`
    width: 330px;
    margin-top: 20px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    @media (max-width:480px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 270px;
    }
`;

export const PasswordIconWrapper = styled.div`
    text-align: center;
    width: 45px;
    padding-top: 7px;
    background: white;
    float: left;
    height: 40px;
`;

export const Input = styled.input`
    width: 285px;
    height: 40px;
    border: none;
    padding-left: 10px;
    outline: none;
    padding-right: 10px;
    font-size: 16px;

    @media (max-width:480px)  {
        width: 260px;
    }

    @media (max-width:320px)  {
        width: 225px;
    }
`;

export const HomeBackButton = styled(LinkRouter)`

`;

export const SignUpButton = styled(LinkRouter)`

`;

export const PasswordForgot = styled(LinkRouter)`
    margin-right: 225px;
    text-decoration: none;
    font-size: 12px;
    color: #ababab;

    @media (max-width:480px)  {
        margin-right: 180px;
    }

    @media (max-width:320px)  {
        margin-right: 150px;
    }
`;

export const SignUp = styled(LinkRouter)`
    text-decoration: none;
    color: #f094a1;
`;

export const SignInLine = styled.div`
    height: 1px;
    width: 100px;
    margin: auto;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    

    @media (max-width:320px)  {
        display: none;
    }
`;

export const SignUpWrapper = styled.div`
    margin-top: 30px;
`;

export const TOS = styled(LinkRouter)`
  text-decoration: none;
  cursor: pointer;
  color: #bfbfbf;

  @media (max-width:320px)  {
    font-size: 12px;
  }

  &:hover {
    color: #5b7329;
  }
`;

export const Line = styled.div`
    height: 2px;
    width: 330px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    

    @media (max-width:480px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 270px;
    }
`;
import React from 'react';
import {
    AboutContainer,
    AboutBackground,
    AboutH1,
    AboutP,
    AboutContentWrapper,
    InstaLink
} from './AboutElements';
import { FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";
import "../animationStyle.css";

const About = () => {
    return (
        <AboutContainer>
            <AboutBackground>
            </AboutBackground>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                className="AboutAnimation"
            >
                <AboutContentWrapper>
                    <AboutP> 
                        Tuinure is a platform for all your fresh vegetables, farm products, fruits, produce and more from local authentic farms. A wide range of products is offered through Tuinure by various farms to fulfill all your fresh needs!  
                    </AboutP>
                    <AboutP> 
                        Tuinure is built on three principles. As a farmer on the platform you are in control from A to Z, from selling to delivery without third party involvement. Upholding transparency towards farms and users of the services is the second key principle that drives us at Tuinure. The most important principle of all is that Tuinure is community driven, helping you succeed through collaborating together. <a href='https://Tuinure.com/signup'>Sign up today!</a>   
                    </AboutP>
                </AboutContentWrapper>

            </motion.div>
            <motion.div className="InstaButton" whileTap={{ scale: 0.9 }}>
                <a href="https://www.instagram.com/Tuinure/">
                    <FaInstagram size={40} color="#5b7329" />
                </a>
            </motion.div>
        </AboutContainer>
    )
}

export default About;

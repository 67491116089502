import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const ForgotPassContainer = styled.div`
width: 100%;
height: 100%;
position: absolute;
display: table;
`;

export const SignInLogo = styled.img`
    margin: auto;
    width: fit-content;

    @media (max-width:320px)  {
        width: 170px;
    }
`;

export const UsernameInputWrapper = styled.div`
    border-width: 0 0 2px 0;
    border-style: solid;
    width: 330px;
    border-image: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
    margin-top: 35px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;

    @media (max-width:480px)  {
        width: 305px;
    }

    @media (max-width:320px)  {
        width: 270px;
    }
`;

export const UsernameIconWrapper = styled.div`
    text-align: center;
    width: 45px;
    padding-top: 7px;
    background: white;
    float: left;
    height: 40px;
`;

export const Input = styled.input`
    width: 285px;
    height: 40px;
    border: none;
    padding-left: 10px;
    outline: none;
    padding-right: 10px;
    font-size: 16px;

    @media (max-width:480px)  {
        width: 260px;
    }

    @media (max-width:320px)  {
        width: 225px;
    }
`;

export const HomeBackButton = styled(LinkRouter)`

`;

// export const ForgotPassButton = styled(LinkRouter)`

// `;

export const SignInLine = styled.div`
    height: 1px;
    width: 100px;
    margin: auto;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
`;

export const TOS = styled(LinkRouter)`
  text-decoration: none;
  cursor: pointer;
  color: #bfbfbf;

  &:hover {
    color: #5b7329;
  }
`;
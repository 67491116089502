import styled from 'styled-components';

export const ProductCardLink = styled.div`

`;

export const ProductCardContents = styled.div`
    box-shadow: 0px 1px 5px 0px #0000001c;
    position: relative;
    border-radius: 10px;
`;

export const CardBackground = styled.img`
    object-fit: cover;
    width: 340px;
    height: 190px;
    display: block;
    border-radius: 10px;

    @media screen and (max-width: 1024px) {
        width: 280px;
        height: 180px;    
    }

    @media screen and (max-width: 768px) {
        width: 223px;
        height: 145px;
    }

    @media (max-width:480px)  {
        width: 100%;
        height: 210px;
    }

    @media (max-width:375px)  {
        height: 200px;
    }

    @media (max-width:320px)  {
        height: 180px;
    }
`;

export const PriceCard = styled.div`
    box-shadow: 0px 1px 5px 0px #0000001c;
    height: fit-content;
    width: 225px;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    border-radius: 10px 0px 0px 10px;
    right: 0;
    margin: auto;
    padding: 10px;
    color: black;
    backdrop-filter: blur(10px);

    @media screen and (max-width: 1024px) {
        width: 175px;
    }

    @media screen and (max-width: 768px) {
        width: 155px;
    }

    @media (max-width:480px)  {
        width: 200px;
    }

    @media (max-width:320px)  {
        width: 180px;
    }
`;

export const PriceTitle = styled.div`
    font-size: 15px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 768px) {
        font-size: 11px;
    }

    @media (max-width:480px)  {
        font-size: 15px;
    }
`;

export const PriceTag = styled.div`
    font-size: 17px;
    margin-top: 5px;

    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width:480px)  {
        font-size: 19px;
    }

    @media (max-width:320px)  {
        font-size: 18px;
    }
`;

export const EditIcon = styled.div`
    position: absolute;
    cursor: pointer;
    z-index: 99;
    margin-left: 10px;
    margin-top: 10px;
`;

export const CloseProduct = styled.div`
    cursor: pointer;
    width: fit-content;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 21;
`;

export const Ready = styled.div`
    font-size: 12px;

    @media screen and (max-width: 768px) {
        font-size: 11px;
    }

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

export const Service = styled.div`
    font-size: 12px;
    bottom: 5px;
    width: fit-content;
    position: absolute;
    color: #000000;
    box-shadow: inset 0px 0px 20px 5px #ffffff6
`;

export const CardLine = styled.div`
    height: 2px;
    width: 70%;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
`;
